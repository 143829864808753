//import Validate from "@/plugins/Validation/Branch/branch"
export default {
    data() {
        return {
            loading: false,
            TableLoading: false,
            listNews: [],
            server_errors: {
                title: '', description: '', imageFile: ''
            },
            news: {},
            image: "",
            imageUrl: "",
            //Pagination
            offset: 15,
            pagination: {},
            per_page: 25,
            headers: [
                {
                    text: "Customer", align: "start", sortable: false, value: "customer",
                },
                {
                    text: "Title", align: "start", sortable: false, value: "title",
                },
                {
                    text: "Content", align: "start", sortable: false, value: "message",
                },
                {text: "Image", value: "image"},
                {text: "Status", value: "status"},
                {
                    text: "Created", align: "start", sortable: false, value: "created_at",
                },
                {text: "Actions", value: "actions", sortable: false},
            ],
            nameRules: [v => !!v || 'Title is required', v => (v && v.length >= 2) || 'Name must be less than 2 characters',],
            descriptionRules: [(v) => !!v || "Description is required"],
            toast: {
                value: true, color: 'success', msg: 'Success'
            },
            toast_error: {
                value: true, color: 'error', msg: 'Something when wrong!'
            }
        }
    }, methods: {
        fetchData() {
            this.TableLoading = true;
            this.$admin.get('requests',
                {
                    params: {
                        page: this.pagination.current_page,
                        per_page: this.per_page,
                    }
                }).then(res => {
                this.listNews = res.data.data.data;
                this.pagination = res.data.data;
                this.TableLoading = false;
            }).catch(() => {
                this.TableLoading = false;
                this.$store.commit("Toast_State", this.toast_error);
            });
        }, AddUser() {
            if (this.$refs.form.validate() == true) {
                this.Submit();
            }
        },

        viewDetail(id) {
            this.$router.push({name: 'CustomerRequestDetail', params: {id: id}});
        },

        closeDelete() {
            this.$store.commit("modalDelete_State", false);
        },

        deleteItem(id) {
            this.dataID = id;
            this.$store.commit("modalDelete_State", true);
        }, deleteItemConfirm() {
            this.loading = true;
            this.$admin.delete('request/' + this.dataID).then(res => {
                if (res.status == 200) {
                    setTimeout(() => {
                        this.fetchData();
                        this.$store.commit("modalDelete_State", false);
                        this.loading = false;
                        this.$store.commit("Toast_State", this.toast);
                    }, 300);
                }
            }).catch(() => {
                this.$store.commit("modalDelete_State", false);
                this.loading = false;
            })
        },
    }, created() {
        this.fetchData();
    },
}
